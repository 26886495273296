import { fetchStart, fetchSuccess, fetchError } from './fetchAction';
import { Dispatch } from "redux";
import _ from "lodash";

import * as billPaymentService from 'src/services/billPaymentService';
//Helpers
import { handleBlobFileDownload } from 'src/helpers/downloadHelper';

export const ACTION_NAME: string = 'BILLPAYMENT_';

export const GET_OUTSTANDINGBILLSCARD = `${ACTION_NAME}GET_OUTSTANDINGBILLSCARD`;
export const getOutstandingBillsCard = (bulkId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_OUTSTANDINGBILLSCARD))
    billPaymentService.getOutstandingBillsCard(bulkId)
      .then(response =>
        dispatch({
          type: GET_OUTSTANDINGBILLSCARD,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(GET_OUTSTANDINGBILLSCARD)))
      .catch(() => dispatch(fetchError(GET_OUTSTANDINGBILLSCARD)));
  };
};

export const GET_OUTSTANDINGCHARGES = `${ACTION_NAME}GET_OUTSTANDINGCHARGES`;
export const getOutstandingCharges = (bulkId: string, recordCount: number) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_OUTSTANDINGCHARGES))
    billPaymentService.getOutstandingCharges(bulkId, recordCount)
      .then(response =>
        dispatch({
          type: GET_OUTSTANDINGCHARGES,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(GET_OUTSTANDINGCHARGES)))
      .catch(() => dispatch(fetchError(GET_OUTSTANDINGCHARGES)));
  };
};

export const GET_OUTSTANDING_BILLS = `${ACTION_NAME}GET_OUTSTANDING_BILLS`;
export const getOutstandingBills = (bulkId: string, recordCount: number) => {
  return (dispatch: Dispatch, getState: any) => {
    const { billPaymentReducer: { bill } } = getState();
    dispatch(fetchStart(GET_OUTSTANDING_BILLS))
    billPaymentService.getOutstandingBills(bulkId, recordCount, _.get(bill, "selectedGroupsForOutstandingBills"))
      .then(response =>
        dispatch({
          type: GET_OUTSTANDING_BILLS,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(GET_OUTSTANDING_BILLS)))
      .catch(() => dispatch(fetchError(GET_OUTSTANDING_BILLS)));
  };
};

export const SET_SELECTED_BILLS_BILL_BATCH = `${ACTION_NAME}SET_SELECTED_BILLS_BILL_BATCH`;
export const setSelectedBillsBillBatch = (billBatchId: string) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_SELECTED_BILLS_BILL_BATCH,
      payload: {
        billBatchId: billBatchId
      }
    })
  };
};

export const SET_SELECTED_BILLS_BILL_STATUS = `${ACTION_NAME}SET_SELECTED_BILLS_BILL_STATUS`;
export const setSelectedBillsBillStatus = (billBatchFilterType: string ,billBatchFilterCode: string) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_SELECTED_BILLS_BILL_STATUS,
      payload: {
        billBatchFilterType:billBatchFilterType,
        billBatchStatus: billBatchFilterCode
      }
    })
  };
};

export const SET_SELECTED_BILLS = `${ACTION_NAME}SET_SELECTED_BILLS`;
export const setSelectedBills = (invoiceNo: string, isSelect: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_SELECTED_BILLS,
      payload: {
        invoiceNo: invoiceNo, 
        isSelect: isSelect
      }
    })
  };
};

export const SAVE_SELECTED_BILLS = `${ACTION_NAME}SAVE_SELECTED_BILLS`;
export const saveSelectedBills = (bills: object[]) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SAVE_SELECTED_BILLS,
      payload: {
        bills
      }
    })
  };
};

export const CLEAR_OUTSTANDING_BILLS = `${ACTION_NAME}CLEAR_OUTSTANDING_BILLS`;
export const clearOutstandingBills = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: CLEAR_OUTSTANDING_BILLS,
      payload: {}
    })
  };
};

export const SELECT_PAYMENTTYPE = `${ACTION_NAME}SELECT_PAYMENTTYPE`;
export const selectPaymentType = (type: string) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SELECT_PAYMENTTYPE,
      payload: {
        type
      }
    })
  };
};

export const GET_UNPAIDBILLHISTORY = `${ACTION_NAME}GET_UNPAIDBILLHISTORY`;
export const getBillsByBatch = (bulkId: string, recordCount: number) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_UNPAIDBILLHISTORY))
    billPaymentService.getBillsByBatch(bulkId, recordCount)
      .then(response =>
        dispatch({
          type: GET_UNPAIDBILLHISTORY,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(GET_UNPAIDBILLHISTORY)))
      .catch(() => dispatch(fetchError(GET_UNPAIDBILLHISTORY)));
  };
};

export const GET_BILLHISTORY = `${ACTION_NAME}GET_BILLHISTORY`;
export const getBillHistory = (bulkId: string, recordCount: number) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_BILLHISTORY))
    billPaymentService.getBillHistory(bulkId, recordCount)
      .then(response =>
        dispatch({
          type: GET_BILLHISTORY,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(GET_BILLHISTORY)))
      .catch(() => dispatch(fetchError(GET_BILLHISTORY)));
  };
};

export const GET_EXCEL_BILL_REPORT = `${ACTION_NAME}GET_EXCEL_BILL_REPORT`;
export const getExcelBillReport = (bulkId: string, fileName: string = 'Bill Report.xlsx') => {
  return (dispatch: Dispatch) => {
    billPaymentService.getExcelBillReport(bulkId)
      .then((response: any) => {
        handleBlobFileDownload(response, fileName);
      })
      .then(() => dispatch(fetchSuccess(GET_EXCEL_BILL_REPORT)))
      .catch(() => dispatch(fetchError(GET_EXCEL_BILL_REPORT)));
  };
};

export const DOWNLOAD_ALLFILES = `${ACTION_NAME}DOWNLOAD_ALLFILES`;
export const downloadAllFiles = (bulkId: string, batchIds: string, extractDates: string) => {
  return (dispatch: Dispatch) => {
    billPaymentService.downloadAllFiles(bulkId, batchIds, extractDates)
      .then((response: any) => {
        if (response instanceof Blob) {
          if (response.type === "application/zip") {
            handleBlobFileDownload(response, `${bulkId}_${extractDates}_${batchIds}.zip`);
          }
          else{
            handleBlobFileDownload(response, `${bulkId}_${extractDates}_${batchIds}.pdf`);
          }
        }
      })
      .then(() => dispatch(fetchSuccess(DOWNLOAD_ALLFILES)))
      .catch(() => dispatch(fetchError(DOWNLOAD_ALLFILES)));
  };
};

export const DOWNLOAD_PDF = `${ACTION_NAME}DOWNLOAD_PDF`;
export const downloadPDF = (caNo: string, invoiceNo: string) => {
  return (dispatch: Dispatch) => {
    billPaymentService.downloadPDF(caNo, invoiceNo)
      .then((response: any) => {
        handleBlobFileDownload(response, `${caNo}_${invoiceNo}.pdf`);
      })
      .then(() => dispatch(fetchSuccess(DOWNLOAD_PDF)))
      .catch(() => dispatch(fetchError(DOWNLOAD_PDF)));
  };
};

export const SEARCH_BILLS = `${ACTION_NAME}SEARCH_BILLS`;
export const searchBills = (bulkId: string, startDate: string, endDate: string, criteria: string, searchString: string, recordCount: number) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(SEARCH_BILLS))
    billPaymentService.searchBills(bulkId, startDate, endDate, criteria, searchString, recordCount)
      .then(response =>
        dispatch({
          type: SEARCH_BILLS,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(SEARCH_BILLS)))
      .catch(() => dispatch(fetchError(SEARCH_BILLS)));
  };
};

export const GET_BANKLIST = `${ACTION_NAME}GET_BANKLIST`;
export const getBankList = () => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_BANKLIST));
    billPaymentService.getBankList()
      .then(response =>
        dispatch({
          type: GET_BANKLIST,
          payload: response
        })
      );
  };
};

export const GET_VIRTUALACCOUNTNO = `${ACTION_NAME}Get_VirtualAccountNo`;
export const getVirtualAccountNo = (bulkId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_VIRTUALACCOUNTNO));
    billPaymentService.getVirtualAccountNo(bulkId)
      .then(response =>
        dispatch({
          type: GET_VIRTUALACCOUNTNO,
          payload: response
        })
      );
  };
};

export const GET_PAYMENTMETHODS = `${ACTION_NAME}GET_PAYMENTMETHODS`;
export const getPaymentMethods = () => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_PAYMENTMETHODS))
    billPaymentService.getPaymentMethods()
      .then(response =>
        dispatch({
          type: GET_PAYMENTMETHODS,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(GET_PAYMENTMETHODS)))
      .catch(() => dispatch(fetchError(GET_PAYMENTMETHODS)));
  };
};

export const MAKE_PAYMENT = `${ACTION_NAME}MAKE_PAYMENT`;
export const MAKE_PAYMENT_FAILED = `${ACTION_NAME}MAKE_PAYMENT_FAILED`;
export const makePayment = (paymentObject: object) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(MAKE_PAYMENT))
    billPaymentService.makePayment(paymentObject)
      .then(response =>
        dispatch({
          type: MAKE_PAYMENT,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(MAKE_PAYMENT)))
      .catch((response) => {
        dispatch({
          type: MAKE_PAYMENT_FAILED,
          payload: response,
        });
        dispatch(fetchError(MAKE_PAYMENT));
      });
  };
};

export const GET_TRANSACTIONSCARDS = `${ACTION_NAME}GET_TRANSACTIONSCARDS`;
export const getTransactionsCards = (bulkId:string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_TRANSACTIONSCARDS))
    billPaymentService.getTransactionsCards(bulkId)
      .then(response =>
        dispatch({
          type: GET_TRANSACTIONSCARDS,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(GET_TRANSACTIONSCARDS)))
      .catch(() => dispatch(fetchError(GET_TRANSACTIONSCARDS)));
  };
};

export const GET_PAYMENTTRANSACTIONS = `${ACTION_NAME}GET_PAYMENTTRANSACTIONS`;
export const getPaymentTransactions = (bulkId: string, recordCount: number) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_PAYMENTTRANSACTIONS))
    billPaymentService.getPaymentTransactions(bulkId, recordCount)
      .then(response =>
        dispatch({
          type: GET_PAYMENTTRANSACTIONS,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(GET_PAYMENTTRANSACTIONS)))
      .catch(() => dispatch(fetchError(GET_PAYMENTTRANSACTIONS)));
  };
};

export const GET_PAYMENTDETAILS = `${ACTION_NAME}GET_PAYMENTDETAILS`;
export const getPaymentDetails = (transactionNo: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_PAYMENTDETAILS))
    billPaymentService.getPaymentDetails(transactionNo)
      .then(response =>
        dispatch({
          type: GET_PAYMENTDETAILS,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(GET_PAYMENTDETAILS)))
      .catch(() => dispatch(fetchError(GET_PAYMENTDETAILS)));
  };
};

export const GET_PAYMENTACTIVITY = `${ACTION_NAME}GET_PAYMENTACTIVITY`;
export const getPaymentActivity = (transactionNo: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_PAYMENTACTIVITY))
    billPaymentService.getPaymentActivity(transactionNo)
      .then(response =>
        dispatch({
          type: GET_PAYMENTACTIVITY,
          payload: { ...response, transactionNo }
        })
      )
      .then(() => dispatch(fetchSuccess(GET_PAYMENTACTIVITY)))
      .catch(() => dispatch(fetchError(GET_PAYMENTACTIVITY)));
  };
};

export const MAKE_RECONCILIATIONPAYMENTINSUFFICIENT = `${ACTION_NAME}MAKE_RECONCILIATIONPAYMENTINSUFFICIENT`;
export const MAKE_RECONCILIATIONPAYMENTINSUFFICIENT_FAILED = `${ACTION_NAME}MAKE_RECONCILIATIONPAYMENTINSUFFICIENT_FAILED`;
export const makeReconciliationPaymentInsufficient = (transactionNo: string, bankId: string, paymentMode: string, bulkChequeEFTNumber: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(MAKE_RECONCILIATIONPAYMENTINSUFFICIENT))
    billPaymentService.makeReconciliationPaymentInsufficient(transactionNo, bankId, paymentMode, bulkChequeEFTNumber)
      .then(response =>
        dispatch({
          type: MAKE_RECONCILIATIONPAYMENTINSUFFICIENT,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(MAKE_RECONCILIATIONPAYMENTINSUFFICIENT)))
      .catch((response) => {
        dispatch({
          type: MAKE_RECONCILIATIONPAYMENTINSUFFICIENT_FAILED,
          payload: response,
        });
        dispatch(fetchError(MAKE_RECONCILIATIONPAYMENTINSUFFICIENT));
      });
  };
};

export const MAKE_EXCESSIVERECONCILIATIONPAYMENT = `${ACTION_NAME}MAKE_EXCESSIVERECONCILIATIONPAYMENT`;
export const MAKE_EXCESSIVERECONCILIATIONPAYMENT_FAILED = `${ACTION_NAME}MAKE_EXCESSIVERECONCILIATIONPAYMENT_FAILED`;
export const makeExcessiveReconciliationPayment = (transactionNo: string, invoiceNo: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(MAKE_EXCESSIVERECONCILIATIONPAYMENT))
    billPaymentService.makeExcessiveReconciliationPayment(transactionNo, invoiceNo)
      .then(response =>
        dispatch({
          type: MAKE_EXCESSIVERECONCILIATIONPAYMENT,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(MAKE_EXCESSIVERECONCILIATIONPAYMENT)))
      .catch((response) => {
        dispatch({
          type: MAKE_EXCESSIVERECONCILIATIONPAYMENT_FAILED,
          payload: response,
        });
        dispatch(fetchError(MAKE_EXCESSIVERECONCILIATIONPAYMENT));
      });
  };
};

export const GET_ELECTRICITYACCOUNT = `${ACTION_NAME}GET_ELECTRICITYACCOUNT`;
export const getElectricityAccount = (transactionNo: string, reconcileAmount: number) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchStart(GET_ELECTRICITYACCOUNT))
    billPaymentService.getElectricityAccount(transactionNo, reconcileAmount)
      .then(response =>
        dispatch({
          type: GET_ELECTRICITYACCOUNT,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(GET_ELECTRICITYACCOUNT)))
      .catch(() => dispatch(fetchError(GET_ELECTRICITYACCOUNT)));
  };
};

export const SET_SELECTED_GROUPS_LIST= `${ACTION_NAME}SET_SELECTED_GROUPS_LIST`;
export const setSelectedGroupsList = (groupId: string[]) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_SELECTED_GROUPS_LIST,
      payload: groupId,
    })
  };
};

export const SET_CUSTOM_SEARCH_IN_UNPAID = `${ACTION_NAME}SET_CUSTOM_SEARCH_IN_UNPAID`;
export const setCustomSearchInUnpaid = (searchBy: string, searchValue: string) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_CUSTOM_SEARCH_IN_UNPAID,
      payload: {
        searchBy,
        searchValue,
      },
    })
  };
};


export const CLEAR_CUSTOM_SEARCH_IN_UNPAID = `${ACTION_NAME}CLEAR_CUSTOM_SEARCH_IN_UNPAID`;
export const clearCustomSearchInUnpaid = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: CLEAR_CUSTOM_SEARCH_IN_UNPAID,
    })
  };
};
